import React from 'react';
import AppCard from '@crema/components/AppCard';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import {useIntl} from 'react-intl';
import {Fonts} from '@crema/constants/AppEnums';
import {MdOutlineMail} from 'react-icons/md';
import {MdOutlineWorkHistory} from 'react-icons/md';
import {Typography} from '@mui/material';

const MyProfile = ({user}: any) => {
  const {photoURL, displayName, userType, uid, email, profession} = user;
  const {messages} = useIntl();

  return (
    <AppCard
      sxStyle={{
        height: 1,
        display: 'flex',
        flexDirection: 'column',
        py: 0,
        px: 0,
      }}
      contentStyle={{py: 0, px: 4}}
      actionStyle={{p: 0, m: 0}}
      footerStyle={{p: 0, m: 0}}
      key={uid}
      title={messages['academy.myProfile'] as string}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: 1,
          height: 1,
        }}
      >
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            mb: {xs: 1.5, sm: 1.2, md: 1},
          }}
        >
          <Avatar
            sx={{
              width: {xs: 50, md: 80, lg: 95, xl: 105},
              height: {xs: 50, md: 80, lg: 95, xl: 105},
              my: 1,
            }}
            src={photoURL ? photoURL : null}
          >
            {photoURL ? null : displayName.charAt(0).toUpperCase()}
          </Avatar>
          <Box
            sx={{
              mb: 1,
              fontSize: 16,
              fontWeight: Fonts.BOLD,
            }}
          >
            {displayName}
          </Box>
          <Typography sx={{color: 'text.secondary'}}>{userType}</Typography>
        </Box>
        <Box
          sx={{
            width: 1,
            p: {xs: 3, xl: 5},
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#E7F4FC',
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              px: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: 1 / 2,
              gap: 2,
            }}
          >
            <MdOutlineMail size={24} />
            <Typography
              sx={{
                fontSize: {xs: 8, md: 10, lg: 12},
              }}
            >
              {email ? email : 'N/A'}
            </Typography>
          </Box>
          <Box
            sx={{
              px: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: 1 / 2,
              borderLeft: 'solid 1px #C8E0EE',
              gap: 2,
            }}
          >
            <MdOutlineWorkHistory size={24} />
            <Typography
              sx={{
                fontSize: {xs: 8, md: 10, lg: 12},
              }}
            >
              {profession ? profession : 'N/A'}
            </Typography>
          </Box>
        </Box>
      </Box>
    </AppCard>
  );
};

export default MyProfile;
