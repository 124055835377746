import React, {Suspense} from 'react';
import {RoutePermittedRole} from '@crema/constants/AppEnums';
import Analytics from '../../../modules/analytics';
import AppLoader from '@crema/components/AppLoader';

export const analyticsConfigs = [
  {
    permittedRole: [
      RoutePermittedRole.User,
      RoutePermittedRole.Admin,
      RoutePermittedRole.SupportAgent,
      RoutePermittedRole.teacher,
    ],
    path: '/analytics',
    element: (
      <Suspense fallback={<AppLoader />}>
        <Analytics />
      </Suspense>
    ),
  },
];
