import axios from '@crema/services/axios';
import type {AxiosResponse} from 'axios';
import {baseUrl} from 'config/utils';

const jwtAxios = axios.create({
  baseURL: baseUrl, // YOUR_API_URL HERE
  headers: {
    'Content-Type': 'application/json',
  },
});

jwtAxios.interceptors.response.use(
  (res: AxiosResponse<any, any>) => res,
  (err: any) => {
    if (err.response && err.response.data.msg === 'Token is not valid') {
      console.log('Need to logout user');
    }
    return Promise.reject(err);
  },
);

export const setAuthToken = (token?: string) => {
  // console.log(token);
  if (token) {
    jwtAxios.defaults.headers.common.Authorization = `Bearer ${token}`;
    localStorage.setItem('JWTnmoAdmin', token);
  } else {
    delete jwtAxios.defaults.headers.common.Authorization;
    localStorage.removeItem('JWTnmoAdmin');
  }
};

export default jwtAxios;
