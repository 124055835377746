import {
  GET_CATEGORY_DATA,
  SET_CAT_DATA,
  GET_TEACHERS_LISTING,
  GET_COURSES_LISTING,
  GET_ADMIN_ANALYTICS,
  GET_ALL_COURSES,
  GET_ALL_USERS,
  GET_CURRENT_COURSE,
  GET_ALL_CATEGORIES,
  SET_ALL_CHAT_USERS,
  GET_NEW_COURSES,
  SET_SPECIFIC_REVIEW,
  SET_SUPPORT_AGENTS,
  SET_CHAT_DATA,
  SET_SELECTED_CHAT_USER,
  GET_ALL_SUPPORT_CHAT_USERS,
  SET_CHAT_USER_IDS,
  GET_PENDING_COURSES,
  SET_COURSE_CONTENT,
  GET_WITHDRAWAL_NOTIFICATIONS,
  SHOW_PROMO_MODAL,
  SET_COURSE_DATA,
  GET_COURSE_ORDER_LIST,
  SET_FINANCE_LIST,
} from '@crema/types/actions/Dashboard.action';

import {createAction} from '@reduxjs/toolkit';
import {CategoryType} from '@crema/types/models/dashboards/Category';
import {CategoryData} from '@crema/types/models/dashboards/CategoryData';

export const GetCategoryList = createAction<CategoryType>(GET_CATEGORY_DATA);
export const GetCategoryData = createAction<CategoryData>(SET_CAT_DATA);
export const GetAllCategories = createAction<any>(GET_ALL_CATEGORIES);

export const GetTeachersListing = createAction<any>(GET_TEACHERS_LISTING);
export const GetCoursesListing = createAction<any>(GET_COURSES_LISTING);

export const GetAdminAnalytics = createAction<any>(GET_ADMIN_ANALYTICS);

export const GetAllCourses = createAction<any>(GET_ALL_COURSES);

export const GetAllUsers = createAction<any>(GET_ALL_USERS);
export const GetChatAllUsers = createAction<any>(SET_ALL_CHAT_USERS);

export const GetCurrentCourse = createAction<any>(GET_CURRENT_COURSE);
export const GetNewCourses = createAction<any>(GET_NEW_COURSES);

export const SetSpecificReview = createAction<any>(SET_SPECIFIC_REVIEW);

export const SetSupportAgents = createAction<any>(SET_SUPPORT_AGENTS);

export const SetChattingData = createAction<any>(SET_CHAT_DATA);

export const SetSelectedChatUser = createAction<any>(SET_SELECTED_CHAT_USER);
export const GetAllSupportChatUsers = createAction<any>(
  GET_ALL_SUPPORT_CHAT_USERS,
);
export const SetAllChatUserIds = createAction<any>(SET_CHAT_USER_IDS);

export const GetPendingCourses = createAction<any>(GET_PENDING_COURSES);

export const SetCourseContent = createAction<any>(SET_COURSE_CONTENT);

export const getWithdrawalNotifications = createAction<any>(
  GET_WITHDRAWAL_NOTIFICATIONS,
);

export const setPromoModal = createAction<boolean>(SHOW_PROMO_MODAL);

export const SETCourseData = createAction<any>(SET_COURSE_DATA);

export const SetMobileViewCourseList = createAction<any>(GET_COURSE_ORDER_LIST);

export const SetFinanceList = createAction<any>(SET_FINANCE_LIST);
