import React from 'react';
import {RoutePermittedRole} from '@crema/constants/AppEnums';
import {TbDeviceDesktopAnalytics} from 'react-icons/tb';
import {MdOutlineLibraryBooks} from 'react-icons/md';
import GroupIcon from '@mui/icons-material/Group';
import {School, ConfirmationNumber} from '@mui/icons-material';
import CategoryIcon from '@mui/icons-material/Category';
import {MdDashboard} from 'react-icons/md';
import {MdSupportAgent} from 'react-icons/md';
import {MdNotificationsActive} from 'react-icons/md';
import {MdQueryStats} from 'react-icons/md';

const routesConfig = [
  {
    id: 'user',
    title: 'Users',
    messageId: 'sidebar.analytics',
    type: 'item',
    permittedRole: [RoutePermittedRole.Admin, RoutePermittedRole.SupportAgent],
    icon: <TbDeviceDesktopAnalytics />,
    url: '/analytics',
  },
  {
    id: 'manage',
    title: 'Manage',
    messageId: 'sidebar.manage',
    type: 'group',
    children: [
      {
        id: 'appmanagement',
        title: 'App Management',
        messageId: 'sidebar.manage.appmanagement',
        type: 'collapse',
        icon: <MdDashboard />,
        permittedRole: [RoutePermittedRole.Admin],
        children: [
          {
            id: 'arrangement',
            title: 'Arrangement',
            messageId: 'Mobile View Arrangement',
            type: 'item',
            permittedRole: [RoutePermittedRole.Admin],
            url: '/coursesarrangement',
          },
          {
            id: 'tax',
            title: 'Tax',
            messageId: 'Tax & Commission',
            type: 'item',
            permittedRole: [RoutePermittedRole.Admin],
            url: '/tax',
          },
          {
            id: 'popular',
            title: 'Popular',
            messageId: 'manage.appmanagement.popular',
            type: 'item',
            permittedRole: [RoutePermittedRole.Admin],
            url: '/popular',
          },
          {
            id: 'trending',
            title: 'Trending',
            messageId: 'manage.appmanagement.trending',
            type: 'item',
            permittedRole: [RoutePermittedRole.Admin],
            url: '/trending',
          },
          {
            id: 'promo',
            title: 'Promo',
            messageId: 'manage.appmanagement.promo',
            type: 'item',
            permittedRole: [RoutePermittedRole.Admin],
            url: '/promo',
          },
          {
            id: 'recommended',
            title: 'Particients',
            messageId: 'participants.also.views',
            type: 'item',
            permittedRole: [RoutePermittedRole.Admin],
            url: '/participientViewed',
          },
          {
            id: 'new',
            title: 'New',
            messageId: 'manage.appmanagement.newcourses',
            type: 'item',
            permittedRole: [RoutePermittedRole.Admin],
            url: '/new',
          },
        ],
      },
      {
        id: 'courses',
        title: 'Courses',
        messageId: 'sidebar.manage.courses',
        type: 'collapse',
        permittedRole: [RoutePermittedRole.Admin],
        icon: <MdOutlineLibraryBooks />,
        children: [
          {
            id: 'create',
            title: 'Create',
            messageId: 'sidebar.manage.courses.create',
            type: 'item',
            permittedRole: [RoutePermittedRole.Admin],
            url: '/create',
          },
          {
            id: 'course',
            title: 'Courses',
            messageId: 'sidebar.manage.active.courses',
            type: 'item',
            permittedRole: [RoutePermittedRole.Admin],
            url: '/courses',
          },
          {
            id: 'pending',
            title: 'Pending Courses',
            messageId: 'Pending Courses',
            type: 'item',
            permittedRole: [RoutePermittedRole.Admin],
            url: '/pending',
          },
        ],
      },
      {
        id: 'stats',
        title: 'Statistics',
        messageId: 'Statistics',
        type: 'collapse',
        permittedRole: [RoutePermittedRole.Admin],
        icon: <MdQueryStats />,
        children: [
          {
            id: 'finance',
            title: 'Finance',
            messageId: 'Finance',
            type: 'item',
            permittedRole: [RoutePermittedRole.Admin],
            url: '/finance',
          },
          {
            id: 'recent_activities',
            title: 'recent_activities',
            messageId: 'Recent Activities',
            type: 'item',
            permittedRole: [RoutePermittedRole.Admin],
            url: '/recentactivities',
          },
          {
            id: 'transactions',
            title: 'Transactions',
            messageId: 'Transactions',
            type: 'item',
            permittedRole: [RoutePermittedRole.Admin],
            url: '/transactions',
          },
        ],
      },
      {
        id: 'users',
        title: 'User',
        messageId: 'sidebar.manage.users.view',
        type: 'item',
        permittedRole: [RoutePermittedRole.Admin],
        url: '/users',
        icon: <GroupIcon />,
      },
      {
        id: 'withdrawal notifications',
        title: 'Withdrawal Notifications',
        messageId: 'Withdrawal Notifications',
        type: 'item',
        permittedRole: [RoutePermittedRole.Admin],
        url: '/withdrawalNotifications',
        icon: <MdNotificationsActive />,
      },
      {
        id: 'categories',
        title: 'Categories',
        messageId: 'dashboard.categories',
        type: 'item',
        permittedRole: [RoutePermittedRole.Admin],
        url: '/categories',
        icon: <CategoryIcon />,
      },
      {
        id: 'instructors',
        title: 'Instructors',
        messageId: 'sidebar.manage.teachers.view',
        type: 'item',
        permittedRole: [RoutePermittedRole.Admin],
        url: '/instructors',
        icon: <School />,
      },
      {
        id: 'support_agents',
        title: 'Support Agents',
        messageId: 'Support Agents',
        type: 'item',
        permittedRole: [RoutePermittedRole.Admin],
        url: '/supportagents',
        icon: <MdSupportAgent />,
      },
      {
        id: 'tickets',
        title: 'Tickets',
        messageId: 'Chat Support',
        type: 'item',
        permittedRole: [
          RoutePermittedRole.SupportAgent,
          RoutePermittedRole.Admin,
        ],
        url: '/chat',
        icon: <ConfirmationNumber />,
      },
    ],
  },
];
export default routesConfig;
