/* eslint-disable prettier/prettier */
import {createReducer} from '@reduxjs/toolkit';
import {CategoryType} from '@crema/types/models/dashboards/Category';
import {CategoryData} from '@crema/types/models/dashboards/CategoryData';
import {
  GetCategoryList,
  GetCategoryData,
  GetTeachersListing,
  GetCoursesListing,
  GetAdminAnalytics,
  GetAllCourses,
  GetAllUsers,
  GetCurrentCourse,
  GetAllCategories,
  GetChatAllUsers,
  GetNewCourses,
  SetSpecificReview,
  SetSupportAgents,
  SetChattingData,
  SetSelectedChatUser,
  GetAllSupportChatUsers,
  SetAllChatUserIds,
  GetPendingCourses,
  SetCourseContent,
  getWithdrawalNotifications,
  setPromoModal,
  SETCourseData,
  SetMobileViewCourseList,
  SetFinanceList,
} from './ActionTypes/Dashboard';

interface initialStateTypes {
  categoryList: CategoryType | null;
  categoryData: CategoryData | null;
  teacherlisting: any | null;
  courseslisting: any | null;
  adminanalytics: any | null;
  allcourseslist: any | object;
  allUsers: any | object | [];
  currentCourse: any | null;
  allCategories: any | [];
  allChatUsers: any | [];
  newCourses: any | [];
  specificReview: any | object;
  supportAgentsListing: any | null;
  chattingData: any | object;
  selectedChatUser: any | object;
  SupportChatUsers: any | object;
  chatUserIds: any | object;
  pendingCourses: any | object;
  courseContent: any | object;
  withdrawalNotifications: any | object;
  promoModal: boolean | null;
  selectedCourse: any | null;
  mobileViewCourses: any | object;
  financeList: any | object;
}

const initialState: initialStateTypes = {
  categoryList: null,
  categoryData: null,
  teacherlisting: [],
  courseslisting: null,
  adminanalytics: null,
  allcourseslist: [],
  allUsers: [],
  currentCourse: null,
  allCategories: [],
  allChatUsers: [],
  newCourses: [],
  specificReview: null,
  supportAgentsListing: [],
  chattingData: [],
  selectedChatUser: null,
  SupportChatUsers: [],
  chatUserIds: [],
  pendingCourses: [],
  courseContent: null,
  withdrawalNotifications: [],
  promoModal: null,
  selectedCourse: null,
  mobileViewCourses: [],
  financeList: null,
};

const dashboardReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(GetCategoryList, (state, action) => {
      state.categoryList = action.payload;
    })
    .addCase(GetCategoryData, (state, action) => {
      state.categoryData = action.payload;
    })
    .addCase(GetAllCategories, (state, action) => {
      state.allCategories = action.payload;
    })
    .addCase(GetTeachersListing, (state: any, action: any) => {
      state.teacherlisting = action.payload;
    })
    .addCase(GetCoursesListing, (state: any, action: any) => {
      state.courseslisting = action.payload;
    })
    .addCase(GetAdminAnalytics, (state: any, action: any) => {
      state.adminanalytics = action.payload;
    })
    .addCase(GetAllCourses, (state: any, action: any) => {
      state.allcourseslist = action.payload;
    })
    .addCase(GetAllUsers, (state: any, action: any) => {
      state.allUsers = action.payload;
    })
    .addCase(GetCurrentCourse, (state: any, action: any) => {
      state.currentCourse = action.payload;
    })
    .addCase(GetChatAllUsers, (state: any, action: any) => {
      state.allChatUsers = action.payload;
    })
    .addCase(GetNewCourses, (state: any, action: any) => {
      state.newCourses = action.payload;
    })
    .addCase(SetSpecificReview, (state: any, action: any) => {
      state.specificReview = action.payload;
    })
    .addCase(SetSupportAgents, (state: any, action: any) => {
      state.supportAgentsListing = action.payload;
    })
    .addCase(SetChattingData, (state: any, action: any) => {
      state.chattingData = action.payload;
    })
    .addCase(SetSelectedChatUser, (state: any, action: any) => {
      state.selectedChatUser = action.payload;
    })
    .addCase(GetAllSupportChatUsers, (state: any, action: any) => {
      state.SupportChatUsers = action.payload;
    })
    .addCase(SetAllChatUserIds, (state: any, action: any) => {
      state.chatUserIds = action.payload;
    })
    .addCase(GetPendingCourses, (state: any, action: any) => {
      state.pendingCourses = action.payload;
    })
    .addCase(SetCourseContent, (state: any, action: any) => {
      state.courseContent = action.payload;
    })
    .addCase(getWithdrawalNotifications, (state: any, action: any) => {
      state.withdrawalNotifications = action.payload;
    })
    .addCase(setPromoModal, (state: any, action: any) => {
      state.promoModal = action.payload;
    })
    .addCase(SETCourseData, (state, action) => {
      state.selectedCourse = action.payload;
    })
    .addCase(SetMobileViewCourseList, (state, action) => {
      state.mobileViewCourses = action.payload;
    })
    .addCase(SetFinanceList, (state, action) => {
      state.financeList = action.payload;
    });
});

export default dashboardReducer;
