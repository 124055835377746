/* eslint-disable prettier/prettier */
import { MetricsType } from '../models/dashboards/Metrics';
import { AnalyticsType } from '../models/dashboards/Analytics';
import { CRMType } from '../models/dashboards/CRM';
import { CryptoPropsType } from '../models/dashboards/Crypto';
import { WidgetsType } from '../models/dashboards/Widgets';
import { AcademyType } from '../models/dashboards/Academy';
import { HealthCareType } from '../models/dashboards/HealthCare';
import { EcommerceType } from '../models/dashboards/Ecommerce';

export const GET_ACADEMY_DATA = 'GET_ACADEMY_DATA';
export const GET_ECOMMERCE_DATA = 'GET_ECOMMERCE_DATA';
export const GET_HEALTH_CARE_DATA = 'GET_HEALTH_CARE_DATA';
export const GET_ANALYTICS_DATA = 'GET_ANALYTICS_DATA';
export const GET_CRM_DATA = 'GET_CRM_DATA';
export const GET_CRYPTO_DATA = 'GET_CRYPTO_DATA';
export const GET_METRICS_DATA = 'GET_METRICS_DATA';
export const GET_WIDGETS_DATA = 'GET_WIDGETS_DATA';
export const GET_CATEGORY_DATA = 'GET_CATEGORY_DATA';
export const SET_CAT_DATA = 'SET_CAT_DATA';
export const GET_TEACHERS_LISTING = 'GET_TEACHERS_LISTING';
export const GET_COURSES_LISTING = 'GET_COURSES_LISTING';
export const GET_ADMIN_ANALYTICS = 'GET_ADMIN_ANALYTICS';
export const GET_ALL_COURSES = 'GET_ALL_COURSES';
export const GET_ALL_USERS = 'GET_ALL_USERS';
export const GET_CURRENT_COURSE = 'GET_CURRENT_COURSE';
export const GET_ALL_CATEGORIES = 'GET_ALL_CATEGORIES';
export const SET_ALL_CHAT_USERS = 'SET_ALL_CHAT_USERS';
export const GET_NEW_COURSES = 'GET_NEW_COURSES';
export const SET_SPECIFIC_REVIEW = 'SET_SPECIFIC_REVIEW';
export const SET_SUPPORT_AGENTS = 'SET_SUPPORT_AGENTS';
export const SET_CHAT_DATA = 'SET_CHAT_DATA';
export const SET_SELECTED_CHAT_USER = 'SET_SELECTED_CHAT_USER';
export const GET_ALL_SUPPORT_CHAT_USERS = 'GET_ALL_SUPPORT_CHAT_USERS';
// export const SET_SUPPORT_SOCKET = 'SET_SUPPORT_SOCKET';
export const SET_CHAT_USER_IDS = 'SET_CHAT_USER_IDS';
export const GET_PENDING_COURSES = 'GET_PENDING_COURSES';
export const SET_COURSE_CONTENT = 'SET_COURSE_CONTENT';
export const GET_WITHDRAWAL_NOTIFICATIONS = 'GET_WITHDRAWAL_NOTIFICATIONS';
export const SHOW_PROMO_MODAL = 'SHOW_PROMO_MODAL';
export const SET_COURSE_DATA = 'SET_COURSE_DATA';

export const GET_COURSE_ORDER_LIST = 'GET_COURSE_ORDER_LIST';
export const SET_FINANCE_LIST = 'SET_FINANCE_LIST';


export type getAllCategories = {
  type: typeof GET_ALL_CATEGORIES;
  payload: any;
};

export type GetAnalyticsAction = {
  type: typeof GET_ANALYTICS_DATA;
  payload: AnalyticsType;
};

export type GetECommerceAction = {
  type: typeof GET_ECOMMERCE_DATA;
  payload: EcommerceType;
};

export type GetHeathCareAction = {
  type: typeof GET_HEALTH_CARE_DATA;
  payload: HealthCareType;
};

export type GetAcademyAction = {
  type: typeof GET_ACADEMY_DATA;
  payload: AcademyType;
};

export type GetCRMAction = {
  type: typeof GET_CRM_DATA;
  payload: CRMType;
};

export type GetCryptosAction = {
  type: typeof GET_CRYPTO_DATA;
  payload: CryptoPropsType;
};

export type GetMetricsAction = {
  type: typeof GET_METRICS_DATA;
  payload: MetricsType;
};

export type GetWidgetsAction = {
  type: typeof GET_WIDGETS_DATA;
  payload: WidgetsType;
};

export type GetCategoryList = {
  type: typeof GET_CATEGORY_DATA;
  payload: any;
};

export type GetCategoryData = {
  type: typeof SET_CAT_DATA;
  payload: any;
};

export type getTeacherListing = {
  type: typeof GET_TEACHERS_LISTING;
  payload: any;
}

export type GetCoursesListing = {
  type: typeof GET_COURSES_LISTING;
  payload: any;
}

export type getAdminAnalytics = {
  type: typeof GET_ADMIN_ANALYTICS;
  payload: any;
}

export type getAllCourses = {
  type: typeof GET_ALL_COURSES;
  payload: any;
};

export type getAllUsers = {
  type: typeof GET_ALL_USERS;
  payload: any;
}

export type getCurrentCourse = {
  type: typeof GET_CURRENT_COURSE;
  payload: any;
};

export type getAllChatUsers = {
  type: typeof SET_ALL_CHAT_USERS;
  payload: any;
};

export type getNewCourses = {
  type: typeof GET_NEW_COURSES;
  payload: any;
};

export type setSpecificReview = {
  type: typeof SET_SPECIFIC_REVIEW;
  payload: any;
};

export type GetSupportAgentsListing = {
  type: typeof SET_SUPPORT_AGENTS;
  payload: any;
};

export type setChatData = {
  type: typeof SET_CHAT_DATA;
  payload: any;
};

export type setSelectedChatUser = {
  type: typeof SET_SELECTED_CHAT_USER;
  payload: any;
};

export type getAllSupportChatUsers = {
  type: typeof GET_ALL_SUPPORT_CHAT_USERS;
  payload: any;
};

// export type setSupportSocket = {
//   type: typeof SET_SUPPORT_SOCKET;
//   payload: any;
// };

export type setChatUserIds = {
  type: typeof SET_CHAT_USER_IDS;
  payload: any;
};

export type getPendingCourses = {
  type: typeof GET_PENDING_COURSES;
  payload: any;
};

export type setCourseContent = {
  type: typeof SET_COURSE_CONTENT;
  payload: any;
};

export type getWithdrawnotifications = {
  type: typeof GET_WITHDRAWAL_NOTIFICATIONS;
  payload: any;
};

export type showPromoModal = {
  type: typeof SHOW_PROMO_MODAL;
  payload: boolean;
};

export type setCourseData = {
  type: typeof SET_COURSE_DATA;
  payload: any;
};

export type getMobileViewCourseslisting = {
  type: typeof GET_COURSE_ORDER_LIST;
  payload: any;
};

export type setFinanceList = {
  type: typeof SET_FINANCE_LIST;
  payload: any;
};

export type DashboardActionTypes =
  | getAllCategories
  | GetECommerceAction
  | GetHeathCareAction
  | GetAcademyAction
  | GetAnalyticsAction
  | GetCRMAction
  | GetCryptosAction
  | GetMetricsAction
  | GetWidgetsAction
  | GetCategoryList
  | GetCategoryData
  | getTeacherListing
  | GetCoursesListing
  | getAdminAnalytics
  | getAllCourses
  | getAllUsers
  | getCurrentCourse
  | getAllChatUsers
  | getNewCourses
  | setSpecificReview
  | GetSupportAgentsListing
  | setChatData
  | setSelectedChatUser
  | getAllSupportChatUsers
  | setChatUserIds
  | getPendingCourses
  | setCourseContent
  | getWithdrawnotifications
  | showPromoModal
  | setCourseData
  | getMobileViewCourseslisting
  | setFinanceList;
